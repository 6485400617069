
import {defineComponent, reactive, ref} from 'vue'
import {Action, FormRules} from 'element-plus'
import 'vue3-video-play/dist/style.css'
import {videoPlay} from 'vue3-video-play'
import axios from "axios";
import { ElMessage, ElMessageBox } from 'element-plus'


export default defineComponent({
  name: 'HomeView',
  components: {
    videoPlay
  },
  setup() {
    const form = ref<any>({
      phone: '',
      code: '',
      name: '',
      companyName: '',
      tag: '',
      scale: '',
      remark: ''
    })

    const formRules = reactive<FormRules>({
        phone: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {min: 11, max: 11, message: '手机号不正确', trigger: 'blur'}
        ]
      }
    )

    const tags = reactive<any>([])
    const scale = reactive<any>([])
    axios.get('http://39.107.96.180:8830/cms/home').then(res => {
      let type = res.data.type
      type.forEach((el: any) => {
        tags.push({
          name: el.dictLabel,
          checked: false,
          dictCode: el.dictValue
        })
      })
      let enterprisesize = res.data.enterprisesize
      enterprisesize.forEach((el: any) => {
        scale.push({
          name: el.dictLabel,
          checked: false,
          dictCode: el.dictValue
        })
      })
    })



    const tagSelect = (index: number) => {
      tags.forEach((el: { checked: boolean; }) => {
        el.checked = false
      })
      tags[index].checked = !tags[index].checked
    }
    const scaleChange = (index: number) => {
      scale.forEach((el: { checked: boolean; }) => {
        el.checked = false
      })
      scale[index].checked = !scale[index].checked
    }
    const videoUrl = ref('http://sites.chenksoft.com/video/RFID资产盘点.mp4')

    const applyVideo = ref<any>(null)

    const changeVideoUrl = (url: string) => {
      videoUrl.value = url
      // 重新加载video
      options.src = url
    }

    const options = reactive({
      width: '100%', // 播放器高度
      height: '100%', // 播放器高度
      color: '#409eff', // 主题色
      title: '', // 视频名称
      webFullScreen: false, // 网页全屏
      speed: true, // 是否支持快进快退
      currentTime: 0, // 跳转到固定播放时间(s)
      muted: false, // 静音
      autoPlay: false, // 自动播放
      loop: false, // 循环播放
      mirror: false, // 镜像画面
      control: true, // 是否显示控制器
      ligthOff: false, // 关灯模式
      volume: 0.3, // 默认音量0-1
      src: 'http://sites.chenksoft.com/video/RFID资产盘点.mp4', // 视频源
      poster: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&domainid=3&fid=2151&filename=2151', // 封面
      speedRate: [1.0, 1.25, 1.5, 2.0], // 可选的播放速度
      controlBtns: [
        'audioTrack', // 音轨切换按钮
        'quality', // 视频质量切换按钮
        'speedRate', // 速率切换按钮
        'volume', // 音量
        'setting', // 设置
        'pip', // 画中画按钮
        'pageFullScreen', // 网页全屏按钮
        'fullScreen'// 全屏按钮
      ]
    })
    const onPlay = () => {
      console.log('播放')
    }

    const industryList = reactive<any>([
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&amp;domainid=3&amp;fid=1761&amp;filename=1761',
        title: '集团企业',
        active: true
      },
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&amp;domainid=3&amp;fid=1759&amp;filename=1759',
        title: 'IT科技',
        active: false
      },
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&domainid=3&fid=1926&filename=1926',
        title: '现代服务业',
        active: false
      },
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&amp;domainid=3&amp;fid=1926&amp;filename=1926',
        title: '生产制造业',
        active: false
      },
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&amp;domainid=3&amp;fid=1925&amp;filename=1925',
        title: '教育科研机构',
        active: false
      },
      {
        src: 'https://hz.chenksoft.com/SK_CFW_Servlet.do?method=downfile&amp;domainid=3&amp;fid=1766&amp;filename=1766',
        title: '政府事业单位',
        active: false
      }

    ])
    const industryActive = ref<number>(0)
    const changeIndustry = (index: number) => {
      industryList.forEach((el: { active: boolean; }) => {
        el.active = false
      })
      industryActive.value = index
      industryList[index].active = !industryList[index].active
    }


    const access = () => {
      const tag = tags.filter((t: any) => t.checked == true)
      form.value.type = tag.map((t: any) => t.dictCode).toString()
      const scaleData = scale.filter((t: any) => t.checked == true)
      form.value.enterpriseSize = scaleData.map((t: any) => t.dictCode).toString()

      // 最后请求参数
      // if(form.value.phone === null || form.value.phone === undefined || form.value.phone === ""){
      //   ElMessage('请输入手机号')
      //   return;
      // }
      if (!form.value.phone) {
        return ElMessage('手机号码不能为空')
      }

      //手机号
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.value.phone)
      if (!phoneReg) {
        ElMessage('手机号码格式不正确')
        return
      }
      if(form.value.name === null || form.value.name === undefined || form.value.name === ""){
        ElMessage('请输入姓名')
        return;
      }
      if(form.value.companyName === null || form.value.companyName === undefined || form.value.companyName === ""){
        ElMessage('请输入公司名称')
        return;
      }
      if(form.value.code === null || form.value.code === undefined || form.value.code === ""){
        ElMessage('请输入短信验证码')
        return;
      }



      if(form.value.remark === null || form.value.remark === undefined || form.value.remark === ""){
        ElMessage('请输入购买需求')
        return;
      }

      //调用后台接口
      axios.post('http://39.107.96.180:8830/cms/addApply',form.value).then(res => {
        ElMessageBox.alert('客户顾问将会在一个工作日内与您联系，呼入电话为 13691576167' +
          '请您保持手机畅通，注意接听。', '提交成功', {
          confirmButtonText: 'OK',
          callback: (action: Action) => {
          },
        })
        tags.forEach((el: { checked: boolean; }) => {
          el.checked = false
        })
        scale.forEach((el: { checked: boolean; }) => {
          el.checked = false
        })
        form.value = {
          phone: '',
          code: '',
          name: '',
          companyName: '',
          tag: '',
          scale: '',
          remark: ''
        }
      })
    }
    const placeholder = ref<string>('短信验证码')
    const timer = ref<any>(null)
    const count = ref<any>(null)
    const isDisabled = ref<any>(false)
    const getCode = () => {
      if (isDisabled.value) {
        return
      }


      //校验手机号
      if (!form.value.phone) {
        ElMessageBox.alert('手机号码不能为空', '提示', {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: 'OK'
        })
        return;
      }

      //手机号
      const phoneReg = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/.test(form.value.phone)
      if (!phoneReg) {
        ElMessageBox.alert('手机号码格式不正确', '提示', {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: 'OK'
        })
        return
      }

      // 请求短信验证码
      axios.get('http://39.107.96.180:8830/sendCode?phone=' + form.value.phone).then(res => {
        // 控制倒计时及按钮是否可以点击
        const TIME_COUNT = 60
        count.value = TIME_COUNT
        timer.value = window.setInterval(() => {
          if (count.value > 0 && count.value <= TIME_COUNT) {
            // 倒计时时不可点击
            isDisabled.value = true
            // 计时秒数
            count.value--
            // 更新按钮的文字内容
            placeholder.value = count.value + 's后重新获取'
          } else {
            // 倒计时完，可点击
            isDisabled.value = false
            // 更新按钮文字内容
            placeholder.value = '短信验证码'
            // 清空定时器!!!
            clearInterval(timer.value)
            timer.value = null
          }
        }, 1000)
      })


    };
    return {
      form,
      formRules,
      tags,
      scale,
      scaleChange,
      videoUrl,
      changeVideoUrl,
      options,
      onPlay,
      applyVideo,
      industryList,
      changeIndustry,
      industryActive,
      access,
      placeholder,
      getCode,
      isDisabled,
      tagSelect
    }
  }
})

